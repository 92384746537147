import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import cn from 'classnames';
import React, { ReactElement } from 'react';


import './CustomDrawer.scss';

const CustomDrawer = ({
  buttonText,
  children,
  handelClear,
  isSelected,
}: {
  buttonText: string;
  children: ReactElement;
  handelClear: any;
  isSelected: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Clone the children and pass handleClose as a prop
  const clonedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { onClick: onClose }),
  );

  return (
    <>
      <Box className={cn('menuButton', isSelected && 'active')}>
        <Button className="btnGreyOutlineDarkAlt" onClick={onOpen}>
          {buttonText}
        </Button>
        <Button
          paddingY={0}
          paddingX={0}
          h={'auto'}
          w={'max-content'}
          minW={'max-content'}
          bg={'transparent'}
          onClick={handelClear}
        >
          {isSelected ? (
            <CloseIcon w={3} h={3} />
          ) : (
            <ChevronDownIcon w={4} h={4} />
          )}
        </Button>
      </Box>

      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        blockScrollOnMount={false}
        placement="bottom"
      >
        <DrawerOverlay />
        <Box className="customDrawer">
          <DrawerContent className="customDrawerContnet">
            <DrawerBody pt={10}>
              <DrawerCloseButton /> {clonedChildren}
            </DrawerBody>
          </DrawerContent>
        </Box>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
