'use client';
import { Box, Container, Heading } from '@chakra-ui/layout';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { assetsUri } from '@/services/api.service';

import styles from './BannerSection.module.scss';

type Props = {
  props: {
    title: string;
    description: string;
    image: string;
    mobileImage: string;
    url: string;
  };
};

const BannerSection = ({ props }: Props) => {
  const imageKeys = {
    desktopKey: 'image-1440-680',
    mobileKey: 'image-430-305',
  };

  return (
    <>
      <Box className={styles.promotionBannerWrapper}>
        <Swiper
          effect={'fade'}
          autoplay={{
            delay: 4500,
          }}
          loop={false}
          navigation={false}
          modules={[EffectFade, Navigation, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          className={styles.bannerSwiper}
        >
          <SwiperSlide>
            <Box
              className={styles.bannerSwiperWrap}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={{ base: 'column', lg: 'row' }}
              height={{ base: 'auto', lg: '450px' }}
            >
              <picture className={styles.bannerImage}>
                <source
                  media="(max-width: 575px)"
                  srcSet={`${assetsUri}/${props.mobileImage}?key=${imageKeys.mobileKey}`}
                />
                <source
                  media="(min-width: 576px)"
                  srcSet={`${assetsUri}/${props.image}?key=${imageKeys.desktopKey}`}
                />
                <img
                  src={`${assetsUri}/${props.image}?key=${imageKeys.desktopKey}`}
                  alt="banner-image"
                  width={1280}
                  height={681}
                />
              </picture>
              <Container
                className={styles.bannerContentWrapper}
                maxW={'1280'}
                width={'100%'}
                alignContent={'center'}
                zIndex={1}
                paddingX={{ base: 6 }}
                paddingY={'0px'}
                marginTop={'auto'}
              >
                <Box className={styles.bannerContent}>
                  <Box className={styles.headingElement} textAlign={'center'}>
                    <Heading
                      as="h1"
                      className={styles.titleLg}
                      color={'white'}
                      marginBottom={4}
                    >
                      {props.title}
                    </Heading>
                    {props?.description && (
                      <Box
                        color={'white'}
                        mb={8}
                        dangerouslySetInnerHTML={{ __html: props.description }}
                      ></Box>
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </>
  );
};

export default BannerSection;
