'use client';
import { Button } from '@chakra-ui/button';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { Hide, Show } from '@chakra-ui/media-query';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/menu';
import cn from 'classnames';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CustomDrawer from '@/app/_components/custom-drawer/CustomDrawer';

import Styles from './FilterSection.module.scss';

type Props = {
  selectedFilter: { [key: string]: string };
  selectedFilterTitles: { [key: string]: string };
  onSetSelectedFilter: any;
  rangesOptions: { value: string, label: string }[];
  specialsCategoriesOptions: { [key: string]: string }[];
  tags: { [key: string]: string }[];
};

const FilterSection = ({
  selectedFilter,
  selectedFilterTitles,
  onSetSelectedFilter,
  rangesOptions,
  specialsCategoriesOptions,
  tags,
}: Props) => {
  return (
    <Box className={Styles.filterWrapper}>
      <Hide breakpoint="(max-width: 768px)">
        <Box className={Styles.filterMenuBar}>
          <Flex
            justifyContent={{ base: 'center' }}
            flexWrap={'nowrap'}
            overflowX={'auto'}
            columnGap={{ base: '2', sm: '4' }}
            paddingLeft={{ base: 0 }}
          >
            <ButtonCustom
              btnText="All"
              className={Styles.menuButton}
              moduleClass={Styles.menuButton}
              extraClass="active"
              onClick={() => onSetSelectedFilter({ model: '' })}
            />
            <Menu>
              <Box
                className={cn(
                  Styles.menuButton,
                  selectedFilter['model'] && Styles.active,
                )}
              >
                <MenuButton
                  as={Button}
                  className={Styles.btnGreyOutlineDarkAlt}
                  isDisabled={rangesOptions.length < 1}
                >
                  {selectedFilter['model'] ? selectedFilterTitles['model'] : 'Model'}
                  {!selectedFilter['model'] && (
                    <ChevronDownIcon w={4} h={4} ml={2} />
                  )}
                </MenuButton>
                {selectedFilter['model'] && (
                  <Button
                    paddingY={0}
                    paddingX={0}
                    h={'auto'}
                    w={'max-content'}
                    minW={'max-content'}
                    bg={'transparent'}
                    onClick={() => onSetSelectedFilter({ ...selectedFilter, model: '' })}
                  >
                    <CloseIcon w={2.5} h={2.5} />
                  </Button>
                )}
              </Box>

              <MenuList
                maxHeight="720px"
                overflowY="auto"
                overflowX="hidden"
                zIndex={99}
                className={Styles.menuList}
              >
                <MenuOptionGroup
                  value={selectedFilter['model'] ?? ''}
                  type="radio"
                  onChange={(val) => onSetSelectedFilter({ ...selectedFilter, model: val })}
                >
                  {rangesOptions.map((item, index) => (
                    <MenuItemOption
                      value={item.value}
                      key={index}
                      paddingY={3}
                      paddingX={6}
                      className={Styles.menuItemOption}
                    >
                      {item.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <Menu>
              <Box
                className={cn(
                  Styles.menuButton,
                  selectedFilter['category'] && Styles.active,
                )}
              >
                <MenuButton
                  as={Button}
                  className={Styles.btnGreyOutlineDarkAlt}
                  isDisabled={specialsCategoriesOptions.length < 1}
                >
                  {selectedFilter['category'] ? selectedFilterTitles['category'] : 'Promo Type'}
                  {!selectedFilter['category'] && (
                    <ChevronDownIcon w={4} h={4} ml={2} />
                  )}
                </MenuButton>
                {selectedFilter['category'] && (
                  <Button
                    paddingY={0}
                    paddingX={0}
                    h={'auto'}
                    w={'max-content'}
                    minW={'max-content'}
                    bg={'transparent'}
                    onClick={() => onSetSelectedFilter({ ...selectedFilter, category: '' })}
                  >
                    <CloseIcon w={2.5} h={2.5} />
                  </Button>
                )}
              </Box>

              <MenuList
                maxHeight="720px"
                overflowY="auto"
                overflowX="hidden"
                zIndex={99}
                className={Styles.menuList}
              >
                <MenuOptionGroup
                  value={selectedFilter['category'] ?? ''}
                  type="radio"
                  onChange={(val) => onSetSelectedFilter({ ...selectedFilter, category: val + '' })}
                >
                  {specialsCategoriesOptions.map((item) => (
                    <MenuItemOption
                      value={item.value + ''}
                      key={item.value}
                      paddingY={3}
                      paddingX={6}
                      className={Styles.menuItemOption}
                    >
                      {item.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <Menu>
              <Box
                className={cn(
                  Styles.menuButton,
                  selectedFilter['tag'] && Styles.active,
                )}
              >
                <MenuButton
                  as={Button}
                  className={Styles.btnGreyOutlineDarkAlt}
                >
                  {selectedFilter['tag'] ? selectedFilterTitles['tag'] : 'Featured Tag'}
                  {!selectedFilter['tag'] && (
                    <ChevronDownIcon w={4} h={4} ml={2} />
                  )}
                </MenuButton>
                {selectedFilter['tag'] && (
                  <Button
                    paddingY={0}
                    paddingX={0}
                    h={'auto'}
                    w={'max-content'}
                    minW={'max-content'}
                    bg={'transparent'}
                    onClick={() => onSetSelectedFilter({ ...selectedFilter, tag: '' })}
                  >
                    <CloseIcon w={2.5} h={2.5} />
                  </Button>
                )}
              </Box>

              <MenuList
                maxHeight="720px"
                overflowY="auto"
                overflowX="hidden"
                zIndex={99}
                className={Styles.menuList}
              >
                <MenuOptionGroup
                  type="radio"
                  value={selectedFilter['tag'] ?? ''}
                  onChange={(val) => onSetSelectedFilter({ ...selectedFilter, tag: val })}
                >
                  {tags.map((item) => (
                    <MenuItemOption
                      value={item.value}
                      key={item.value}
                      paddingY={3}
                      paddingX={6}
                      className={Styles.menuItemOption}
                    >
                      {item.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
      </Hide>
      <Show breakpoint="(max-width: 767px)">
        <Flex
          flexWrap={'nowrap'}
          overflowX={'auto'}
          columnGap={{ base: '2', sm: '4' }}
          padding={2}
          alignItems={'center'}
          maxWidth={'fit-content'}
          marginX={'auto'}
        >
          <Box>
            <ButtonCustom
              btnText="All"
              className={Styles.menuButton}
              moduleClass={Styles.menuButton}
              extraClass="active"
              onClick={() => onSetSelectedFilter({ model: '' })}
            />
          </Box>
          <CustomDrawer
            buttonText={selectedFilter['model'] ? selectedFilterTitles['model'] : 'Model'}
            handelClear={() => onSetSelectedFilter({ model: '' })}
            isSelected={Boolean(selectedFilter['model'])}
          >
            <Stack gap={0} marginX={'-1.5em'}>
              {rangesOptions.map((item, index) => (
                <Text
                  key={index}
                  className={Styles.menuLink}
                  as="button"
                  onClick={() => onSetSelectedFilter({ model: item.value })}
                >
                  {item.label}
                </Text>
              ))}
            </Stack>
          </CustomDrawer>
          <CustomDrawer
            buttonText={
              selectedFilter['category'] ? selectedFilterTitles['category'] : 'Promo Type'
            }
            handelClear={() => onSetSelectedFilter({ model: '' })}
            isSelected={Boolean(selectedFilter['category'])}
          >
            <Stack gap={0} marginX={'-1.5em'}>
              {specialsCategoriesOptions.map((item, index) => (
                <Text
                  key={index}
                  className={Styles.menuLink}
                  as="button"
                  onClick={() => onSetSelectedFilter({ category: item.value })}
                >
                  {item.label}
                </Text>
              ))}
            </Stack>
          </CustomDrawer>
          <CustomDrawer
            buttonText={selectedFilter['tag'] ? selectedFilterTitles['tag'] : 'Featured Tag'}
            handelClear={() => onSetSelectedFilter({ model: '' })}
            isSelected={Boolean(selectedFilter['tag'])}
          >
            <Stack gap={0} marginX={'-1.5em'}>
              {tags.map((item, index) => (
                <Text
                  key={index}
                  className={Styles.menuLink}
                  as="button"
                  onClick={() => {
                    onSetSelectedFilter({ tag: item.value });
                  }}
                >
                  {item.label}
                </Text>
              ))}
            </Stack>
          </CustomDrawer>
        </Flex>
      </Show>
    </Box>
  );
};

export default FilterSection;
